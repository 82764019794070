<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 商品结算
 * @Date: 2020-12-25 14:40:05
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-25 17:55:44
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/commodity.vue
-->
<template>
  <div class="user_content">
    <van-tabs v-model="active" background="#f6f6f6" color="#ee4a0a" line-width="0" @click="onClick">
      <van-tab title="累计" />
      <van-tab title="今日" />
      <van-tab title="昨日" />
      <van-tab title="本月" />
      <van-tab title="自定义" />
    </van-tabs>
    <div v-if="show" class="custom">
      <div class="cu_item">
        <div>开始时间</div>
        <div @click="xztime(1)">2020-12-25
          <van-icon name="arrow-down" />
        </div>
      </div>
      <div class="cu_item">
        <div>订单数量</div>
        <div>1</div>
      </div>
      <div class="cu_item">
        <div>结束时间</div>
        <div @click="xztime(2)">2020-12-25
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <van-tabs v-model="actlis" color="#ee4a0a" @click="actlis">
      <van-tab :title="'结算金额: ¥ ' + actlis" />
      <van-tab :title="'待到账金额: ¥ ' + actlis" />
    </van-tabs>
    <van-popup v-model="time" position="bottom">
      <van-datetime-picker
        v-if="thye === 1"
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        @cancel="cancel"
        @confirm="confirm"
      />
      <van-datetime-picker
        v-if="thye === 2"
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        @cancel="cancel"
        @confirm="confirm"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { DatetimePicker, Icon, Popup, Tab, Tabs } from 'vant'

Vue.use(Tab).use(Tabs).use(Icon).use(Popup).use(DatetimePicker)
export default {
  data() {
    return {
      thye: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      active: 0,
      actlis: 0,
      page: '',
      type: '',
      show: false,
      time: false
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
    },
    onClick(t) {
      this.show = t === 4
    },
    xztime(t) {
      this.thye = t
      this.time = true
    },
    // 取消
    cancel() {
      this.time = false
    },
    confirm(v) {
      this.time = false
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding-bottom: 80px;

  .custom {
    background: #f6f6f6;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cu_item {
      & > div:nth-child(1) {
        font-size: 12px;
        margin: 5px 0;
      }

      & > div:nth-child(2) {
        font-size: 12px;
        background: #fff;
        padding: 3px 10px;
        border-radius: 12px;
      }
    }
  }
}
</style>
<style>
.van-tab--active {
  color: #ee4a0a !important;
}

.van-tabs__line {
  background-color: #ee4a0a !important;
}
</style>

